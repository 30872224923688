<template>
  <Page title="Absenzenwesen" color="info">
    <template slot="extension">
      <v-tabs
        background-color="info darken-1"
        slider-color="white"
        :align-with-title="!$vuetify.breakpoint.xs"
      >
        <v-tab
          :to="{
            name: 'AbsencesPending',
          }"
          >Pendenzen</v-tab
        >
        <v-tab
          :to="{
            name: 'AbsencesOverview',
          }"
          >Übersicht</v-tab
        >
        <v-tab
          :to="{
            name: 'AbsencesBalance',
          }"
          >Abschluss</v-tab
        >
        <v-tab
          :to="{
            name: 'AbsencesInformation',
          }"
          >Informationen</v-tab
        >
      </v-tabs>
    </template>

    <v-btn
      color="success"
      fab
      fixed
      bottom
      right
      @click="addJustification"
      v-if="$route.path.includes('overview') || $route.path.includes('pending')"
      ><v-icon>mdi-plus</v-icon></v-btn
    >

    <v-container class="mb-4">
      <v-row class="align-center justify-center">
        <SchoolClassPicker
          outlined
          text
          v-model="schoolClass"
          :disabled="$route.name == 'AbsencesOverviewStudent'"
        />
        <v-spacer></v-spacer>

        <TermPicker
          outlined
          text
          v-model="term"
          :range="[-3, 1]"
          v-if="$route.path.includes('overview')"
        />

        <v-spacer />

        <ReportButton
          v-show="$route.name == 'AbsencesOverviewStudent'"
          text
          :icon="$vuetify.breakpoint.xs"
          :outlined="!$vuetify.breakpoint.xs"
          color="info"
          :parameters="{
            id: $route.params.id,
            term: term ? term.id : 0,
          }"
          resource="report/absencescommittee"
          >{{
            $vuetify.breakpoint.xs
              ? ""
              : $vuetify.breakpoint.mobile
              ? "Meldung"
              : "Meldung häufiger Absenzen"
          }}</ReportButton
        >
      </v-row>
    </v-container>
    <router-view :schoolClass="schoolClass" :term="term" />
  </Page>
</template>
<script>
import ReportButton from "@/components/ReportButton.vue";
import SchoolClassPicker from "@/components/SchoolClassPicker.vue";
import TermPicker from "common/components/TermPicker.vue";

export default {
  components: {
    ReportButton,
    SchoolClassPicker,
    TermPicker,
  },

  data() {
    return {
      items: [],
      schoolClass: null,
      term: null,
    };
  },
  methods: {
    addJustification() {
      if (this.$_hasRole("student")) {
        this.$router.push({
          name: "JustificationStudentEdit",
          params: { justificationId: 0 },
        });
      } else if (this.$route.name == "AbsencesPending") {
        this.$router.push({
          name: "JustificationPendingEdit",
          params: { justificationId: 0 },
        });
      } else if (this.$route.name == "AbsencesOverview") {
        this.$router.push({
          name: "JustificationOverviewAdd",
          params: { justificationId: 0 },
        });
      } else {
        this.$router.push({
          name: "JustificationOverviewEdit",
          params: { justificationId: 0 },
        });
      }
    },
  },
  created() {},
};
</script>

<style scoped>
.fab-container {
  position: fixed;
  bottom: 0;
  right: 0;
}
</style>
